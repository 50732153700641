import { default as balanceQaPywToU55Meta } from "/app/pages/balance.vue?macro=true";
import { default as creativesBaSyqVZAZyMeta } from "/app/pages/creatives.vue?macro=true";
import { default as depositLK2Y2mAC0KMeta } from "/app/pages/deposit.vue?macro=true";
import { default as _91id_93MTc9cEKfEoMeta } from "/app/pages/group/telegram/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as _1pSUAdLJcxRMeta } from "/app/pages/order/1.vue?macro=true";
import { default as _2ON8T3lCyd0Meta } from "/app/pages/order/2.vue?macro=true";
import { default as _3aqSAkQooZ3Meta } from "/app/pages/order/3.vue?macro=true";
import { default as _4YcCaUTCwedMeta } from "/app/pages/order/4.vue?macro=true";
import { default as indexBpuRKxqKBqMeta } from "/app/pages/orders/[id]/index.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as index8EwMPkoZPEMeta } from "/app/pages/publisher/index.vue?macro=true";
import { default as payments263AuMRhvQMeta } from "/app/pages/publisher/payments.vue?macro=true";
import { default as placeWqQnxq7UhZMeta } from "/app/pages/publisher/place.vue?macro=true";
import { default as _91id_93nkis4gcu8oMeta } from "/app/pages/publisher/places/[id].vue?macro=true";
import { default as newpcwIWraKLDMeta } from "/app/pages/publisher/places/new.vue?macro=true";
import { default as publicationsonHifNIaUlMeta } from "/app/pages/publisher/publications.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as supportPKo8CaxFTuMeta } from "/app/pages/support.vue?macro=true";
import { default as confirms4Kmv58cXaMeta } from "/app/pages/user/confirm.vue?macro=true";
export default [
  {
    name: balanceQaPywToU55Meta?.name ?? "balance",
    path: balanceQaPywToU55Meta?.path ?? "/balance",
    meta: balanceQaPywToU55Meta || {},
    alias: balanceQaPywToU55Meta?.alias || [],
    redirect: balanceQaPywToU55Meta?.redirect,
    component: () => import("/app/pages/balance.vue").then(m => m.default || m)
  },
  {
    name: creativesBaSyqVZAZyMeta?.name ?? "creatives",
    path: creativesBaSyqVZAZyMeta?.path ?? "/creatives",
    meta: creativesBaSyqVZAZyMeta || {},
    alias: creativesBaSyqVZAZyMeta?.alias || [],
    redirect: creativesBaSyqVZAZyMeta?.redirect,
    component: () => import("/app/pages/creatives.vue").then(m => m.default || m)
  },
  {
    name: depositLK2Y2mAC0KMeta?.name ?? "deposit",
    path: depositLK2Y2mAC0KMeta?.path ?? "/deposit",
    meta: depositLK2Y2mAC0KMeta || {},
    alias: depositLK2Y2mAC0KMeta?.alias || [],
    redirect: depositLK2Y2mAC0KMeta?.redirect,
    component: () => import("/app/pages/deposit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MTc9cEKfEoMeta?.name ?? "group-telegram-id",
    path: _91id_93MTc9cEKfEoMeta?.path ?? "/group/telegram/:id()",
    meta: _91id_93MTc9cEKfEoMeta || {},
    alias: _91id_93MTc9cEKfEoMeta?.alias || [],
    redirect: _91id_93MTc9cEKfEoMeta?.redirect,
    component: () => import("/app/pages/group/telegram/[id].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: newsWdQv0BaSvYMeta?.name ?? "news",
    path: newsWdQv0BaSvYMeta?.path ?? "/news",
    meta: newsWdQv0BaSvYMeta || {},
    alias: newsWdQv0BaSvYMeta?.alias || [],
    redirect: newsWdQv0BaSvYMeta?.redirect,
    component: () => import("/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: _1pSUAdLJcxRMeta?.name ?? "order-1",
    path: _1pSUAdLJcxRMeta?.path ?? "/order/1",
    meta: _1pSUAdLJcxRMeta || {},
    alias: _1pSUAdLJcxRMeta?.alias || [],
    redirect: _1pSUAdLJcxRMeta?.redirect,
    component: () => import("/app/pages/order/1.vue").then(m => m.default || m)
  },
  {
    name: _2ON8T3lCyd0Meta?.name ?? "order-2",
    path: _2ON8T3lCyd0Meta?.path ?? "/order/2",
    meta: _2ON8T3lCyd0Meta || {},
    alias: _2ON8T3lCyd0Meta?.alias || [],
    redirect: _2ON8T3lCyd0Meta?.redirect,
    component: () => import("/app/pages/order/2.vue").then(m => m.default || m)
  },
  {
    name: _3aqSAkQooZ3Meta?.name ?? "order-3",
    path: _3aqSAkQooZ3Meta?.path ?? "/order/3",
    meta: _3aqSAkQooZ3Meta || {},
    alias: _3aqSAkQooZ3Meta?.alias || [],
    redirect: _3aqSAkQooZ3Meta?.redirect,
    component: () => import("/app/pages/order/3.vue").then(m => m.default || m)
  },
  {
    name: _4YcCaUTCwedMeta?.name ?? "order-4",
    path: _4YcCaUTCwedMeta?.path ?? "/order/4",
    meta: _4YcCaUTCwedMeta || {},
    alias: _4YcCaUTCwedMeta?.alias || [],
    redirect: _4YcCaUTCwedMeta?.redirect,
    component: () => import("/app/pages/order/4.vue").then(m => m.default || m)
  },
  {
    name: indexBpuRKxqKBqMeta?.name ?? "orders-id",
    path: indexBpuRKxqKBqMeta?.path ?? "/orders/:id()",
    meta: indexBpuRKxqKBqMeta || {},
    alias: indexBpuRKxqKBqMeta?.alias || [],
    redirect: indexBpuRKxqKBqMeta?.redirect,
    component: () => import("/app/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1Ehu1sqkUMeta?.name ?? "orders",
    path: indexG1Ehu1sqkUMeta?.path ?? "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    alias: indexG1Ehu1sqkUMeta?.alias || [],
    redirect: indexG1Ehu1sqkUMeta?.redirect,
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: index8EwMPkoZPEMeta?.name ?? "publisher",
    path: index8EwMPkoZPEMeta?.path ?? "/publisher",
    meta: index8EwMPkoZPEMeta || {},
    alias: index8EwMPkoZPEMeta?.alias || [],
    redirect: index8EwMPkoZPEMeta?.redirect,
    component: () => import("/app/pages/publisher/index.vue").then(m => m.default || m)
  },
  {
    name: payments263AuMRhvQMeta?.name ?? "publisher-payments",
    path: payments263AuMRhvQMeta?.path ?? "/publisher/payments",
    meta: payments263AuMRhvQMeta || {},
    alias: payments263AuMRhvQMeta?.alias || [],
    redirect: payments263AuMRhvQMeta?.redirect,
    component: () => import("/app/pages/publisher/payments.vue").then(m => m.default || m)
  },
  {
    name: placeWqQnxq7UhZMeta?.name ?? "publisher-place",
    path: placeWqQnxq7UhZMeta?.path ?? "/publisher/place",
    meta: placeWqQnxq7UhZMeta || {},
    alias: placeWqQnxq7UhZMeta?.alias || [],
    redirect: placeWqQnxq7UhZMeta?.redirect,
    component: () => import("/app/pages/publisher/place.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nkis4gcu8oMeta?.name ?? "publisher-places-id",
    path: _91id_93nkis4gcu8oMeta?.path ?? "/publisher/places/:id()",
    meta: _91id_93nkis4gcu8oMeta || {},
    alias: _91id_93nkis4gcu8oMeta?.alias || [],
    redirect: _91id_93nkis4gcu8oMeta?.redirect,
    component: () => import("/app/pages/publisher/places/[id].vue").then(m => m.default || m)
  },
  {
    name: newpcwIWraKLDMeta?.name ?? "publisher-places-new",
    path: newpcwIWraKLDMeta?.path ?? "/publisher/places/new",
    meta: newpcwIWraKLDMeta || {},
    alias: newpcwIWraKLDMeta?.alias || [],
    redirect: newpcwIWraKLDMeta?.redirect,
    component: () => import("/app/pages/publisher/places/new.vue").then(m => m.default || m)
  },
  {
    name: publicationsonHifNIaUlMeta?.name ?? "publisher-publications",
    path: publicationsonHifNIaUlMeta?.path ?? "/publisher/publications",
    meta: publicationsonHifNIaUlMeta || {},
    alias: publicationsonHifNIaUlMeta?.alias || [],
    redirect: publicationsonHifNIaUlMeta?.redirect,
    component: () => import("/app/pages/publisher/publications.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? "settings",
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: supportPKo8CaxFTuMeta?.name ?? "support",
    path: supportPKo8CaxFTuMeta?.path ?? "/support",
    meta: supportPKo8CaxFTuMeta || {},
    alias: supportPKo8CaxFTuMeta?.alias || [],
    redirect: supportPKo8CaxFTuMeta?.redirect,
    component: () => import("/app/pages/support.vue").then(m => m.default || m)
  },
  {
    name: confirms4Kmv58cXaMeta?.name ?? "user-confirm",
    path: confirms4Kmv58cXaMeta?.path ?? "/user/confirm",
    meta: confirms4Kmv58cXaMeta || {},
    alias: confirms4Kmv58cXaMeta?.alias || [],
    redirect: confirms4Kmv58cXaMeta?.redirect,
    component: () => import("/app/pages/user/confirm.vue").then(m => m.default || m)
  }
]